import React, {ErrorInfo} from 'react';
import {withRouter, RouteComponentProps} from 'react-router';
import {UnregisterCallback} from 'history';

interface ErrorState {
  readonly error?: any;
}

const HandleErrors = withRouter(
  class extends React.Component<RouteComponentProps, ErrorState> {
    readonly state: ErrorState = {};
    unregisterHistoryListener?: UnregisterCallback;

    componentWillMount() {
      window.addEventListener('error', this.setErrorState, true);
      window.addEventListener('unhandledrejection', this.setErrorState);
      this.props.history.listen(() => this.setState({error: undefined}));
    }

    componentWillUnmount() {
      window.removeEventListener('error', this.setErrorState, true);
      window.removeEventListener('unhandledrejection', this.setErrorState);
      this.unregisterHistoryListener && this.unregisterHistoryListener();
    }

    setErrorState = (error: any) => {
      console.error(error);
      this.setState({error});
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
      this.setErrorState(error);
    }

    render() {
      if (this.state.error != null) {
        return <>
          <h1>¯\_(ツ)_/¯</h1>
          <h2>An error occured</h2>
        </>;
      } else {
        return this.props.children;
      }
    }
  }
);

export default HandleErrors;
