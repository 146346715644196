import React from 'react';
import {Trans} from 'react-i18next';
import {LocationDescriptorObject} from 'history';
import {Route, Link, NavLink, RouteComponentProps, Switch, generatePath} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {faHome, faList, faFilm} from '@fortawesome/free-solid-svg-icons';

import Home from './Home';
import Recordings from './recordings';
import Courses from './courses';
import Recording from './Recording';
import Course from './Course';
import {SearchResults} from './search';

import {tuple} from './util';

import styles from './Navigation.module.css';

type RouteProps<
  PathParams extends string,
  QueryParams extends string,
> = {
  [Param in Exclude<PathParams, ''>]: string
} & {
  [Param in QueryParams]?: string
};

type NavigationLinkProps<Props> = React.PropsWithChildren<{
  icon: IconDefinition,
  exact?: boolean,
} & Props> | null

interface Destination<
  Id extends string,
  PathParams extends string,
  QueryParams extends string,
  Props extends RouteProps<PathParams, QueryParams>,
> {
  readonly id: Id;
  readonly path: string;
  readonly navigationLinkProps: NavigationLinkProps<Props>;
  readonly routeTo: (props: Props) => LocationDescriptorObject;
  readonly Link: React.FC<Props>;
  readonly Component: React.ComponentType<RouteComponentProps<Props>>;
};

const drawRoute = <
  Id extends string,
  PathParams extends string,
  QueryParams extends string,
  Props extends RouteProps<PathParams, QueryParams>,
>({
  id,
  navigationLinkProps,
  pathSegments,
  queryParamNames = [],
  component: Component,
}: {
  id: Id,
  navigationLinkProps: NavigationLinkProps<Props>,
  pathSegments: [string, PathParams][],
  queryParamNames?: QueryParams[],
  component: React.ComponentType<Props>,
}): Destination<Id, PathParams, QueryParams, Props> => {
  const path = `/${pathSegments.map(
    ([resource, param]) => `${resource}${param && `/:${param}`}`,
  ).join('/')}`;
  const routeTo = (props: Props): LocationDescriptorObject => {
    const queryParams = new URLSearchParams();
    for (const queryParamName of queryParamNames) {
      queryParams.append(queryParamName, props[queryParamName]);
    }
    return {
      pathname: generatePath(path, props),
      search: `${queryParams}`,
    };
  };
  return {
    id,
    path,
    navigationLinkProps,
    routeTo,
    Link: (props) => <Link to={routeTo(props)}>
      {props.children}
    </Link>,
    Component: ({match: {params}, location: {search}}) => {
      const queryParams = new URLSearchParams(search);
      const queryParamsObject: Record<string, string> = {};
      for (const queryParam of queryParamNames) {
        const queryParamValue = queryParams.get(queryParam);
        if (queryParamValue != null) {
          queryParamsObject[queryParam] = queryParams.get(queryParam)!;
        }
      }
      return <Component {...params} {...queryParamsObject} />;
    },
  };
};

const navigation = tuple(drawRoute({
  id: 'home',
  navigationLinkProps: {icon: faHome, children: 'Home', exact: true},
  pathSegments: [['', '']],
  component: Home,
}), drawRoute({
  id: 'courses',
  navigationLinkProps: {icon: faList, children: 'Courses'},
  pathSegments: [['courses', '']],
  component: Courses,
}), drawRoute({
  id: 'recordings',
  navigationLinkProps: {icon: faFilm, children: 'Recordings'},
  pathSegments: [['recordings', '']],
  component: Recordings,
}), drawRoute({
  id: 'course',
  navigationLinkProps: null,
  pathSegments: [['courses', 'id']],
  component: Course,
}), drawRoute({
  id: 'recording',
  navigationLinkProps: null,
  pathSegments: [['recordings', 'id']],
  component: Recording,
}), drawRoute({
  id: 'search',
  navigationLinkProps: null,
  pathSegments: [['search', '']],
  queryParamNames: ['query'],
  component: SearchResults,
}));
// Typecheck the navigation
(<D extends Destination<any, any, any, any>[]>(...navigation: D) => {})(...navigation);

// Note: I know this could be automated,
// but please refrain from doing so.
// We do it this way to retain type information.
const sitemap = {
  home: navigation[0],
  courses: navigation[1],
  recordings: navigation[2],
  course: navigation[3],
  recording: navigation[4],
  search: navigation[5],
};
export default sitemap;
// Typecheck the sitemap
type Destinations = typeof navigation extends Destination<infer Id, any, any, any>[] ? Id : never;
((sitemap: {[D in Destinations]: Destination<D, any, any, any>}) => {})(sitemap);

export const Navigation: React.FC = () => <ul className={styles.navigation}>{
  navigation.filter(({navigationLinkProps}) => navigationLinkProps).map(
    ({routeTo, navigationLinkProps}, i) => {
      const {children, exact = false, icon, ...props} = navigationLinkProps!;
      // Using the index for the `key` is fine here,
      // since the `navigation` array should never change.
      return <li key={i}>
        <NavLink
          activeClassName={styles.active}
          exact={exact}
          // This should be safe due to the type gymnastics in `makePage`
          to={routeTo(props as any)}
        >
          <span className={styles.icon}>
            <FontAwesomeIcon icon={icon} />
          </span>
          <Trans>{children}</Trans>
        </NavLink>
      </li>;
    }
  )
}</ul>;

export const Routes: React.FC = () => <Switch>{
  navigation.map(({path, Component}, i) =>
    // Using the index as the key here is fine, since `navigation` won't ever change
    <Route exact key={i} {...{path}} render={
      (routeProps) => <Component {...routeProps} />
    } />
  )
}</Switch>;
