import i18n from "i18next";
import { initReactI18next as reactIntegration } from "react-i18next";
import browserDetector from "i18next-browser-languagedetector";

i18n
  .use(reactIntegration)
  .use(browserDetector)
  .init({
    resources: {
      de: {
        translation: {
          // Page title
          "Videogallery Universität Osnabrück":
            "Videogalerie Universität Osnabrück",

          // Navigation
          Home: "Startseite",

          // Authentication
          "Log In": "Anmelden",
          "Log in": "Anmelden",
          username: "Benutzername",
          password: "Passwort",
          "":
          "",
          "Log Out": "Abmelden",

          // Search
          'Search results for "{{query}}"': "Suchergebnisse für „{{query}}“",

          // Home
          "New Recordings": "Neue $t(Recordings)",
          "Watch now": "Jetzt ansehen",

          // Courses
          "Courses ({{count}})": "$t(Courses) ({{count}})",
          Location: "Ort",

          // Recordings
          "Recordings ({{count}})": "$t(Recordings) ({{count}})",
          Course: "Kurs",
          Date: "Datum",
          Duration: "Länge",

          // Misc
          Recordings: "Aufzeichnungen",
          Courses: "Kurse",
          Title: "Titel",
          Presenters: "Vortragende",

          // 404
          "<0>Not found</0><p>The site you requested could not be found.</p>":
            "<0>Nicht gefunden</0><p>Die Seite, die Sie angefordert haben, konnte nicht gefunden werden.</p>",
          "<0>Page not visible in anonymous mode</0><1>You possibly try to access a page which is only visible when you are logged in.<br/>Please log in in the upper right corner!</1>":
            "<0>Seite in anonymer Ansicht nicht sichtbar</0><1>Sie versuchen möglicherweise eine Aufzeichnung aufzurufen die nur in eingeloggtem Zustand sichtbar ist.<br/>Bitte loggen Sie sich rechts oben ein!</1>",

          // Selecting the right logo (see below)
          "logos/en.svg": "logos/UOSde.svg",
          // alt for the logo
          "Universtität Osnabrück": "Universtität Osnabrück",

          // Pagination
          "First page": "Erste Seite",
          "Previous page": "Vorherige Seite",
          "Page {{page}}": "Seite {{page}}",
          "Next page": "Nächste Seite",
          "Last page": "Letzte Seite",
          "Items per page": "Einträge pro Seite",
          Reload: "Neu laden"
        }
      }
    },

    // We use content as translation keys
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
      // React takes care of this for us
      escapeValue: false
    }
  });

export default i18n;
