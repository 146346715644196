import React, {useContext, useEffect} from 'react';
import {useOpencast} from './opencast';

export class AnonymousUser {
  readonly name = 'anonymous';
  readonly roles = ['ROLE_ANONYMOUS'];
  readonly isLoggedIn = false;
}

export class AuthenticatedUser {
  readonly name: string;
  readonly roles: string[];
  readonly isLoggedIn = true;
  constructor(name: string, roles: string[]) {
    this.name = name;
    this.roles = roles;
  }
}

export type User = AnonymousUser | AuthenticatedUser;

export const isLoggedIn = (user: User): user is AuthenticatedUser =>
  user.isLoggedIn;

export const ANONYMOUS = new AnonymousUser();

export const CurrentUser = React.createContext<{
  currentUser: User,
  refreshSession: () => void,
}>({
  currentUser: ANONYMOUS,
  refreshSession: () => {},
});

export const Authenticated: React.FC = ({children}) => {
  const [currentUser, setCurrentUser] = React.useState<User>(ANONYMOUS);
  const opencast = useOpencast();

  const refreshSession = React.useCallback(
    async () => setCurrentUser(await opencast.currentUser()),
    [opencast, setCurrentUser],
  );

  useEffect(
    () => { refreshSession(); },
    [refreshSession],
  );

  return <CurrentUser.Provider value={{
    currentUser,
    refreshSession,
  }}>
    {children}
  </CurrentUser.Provider>;
};

export const useCurrentUser = () => useContext(CurrentUser);
