import React from 'react';
import {tuple} from './util';

const useControl = <T>(initialValue: T) => {
  const [value, setValue] = React.useState(initialValue);
  const onChange = React.useCallback(
    ({target: {value}}) => setValue(value),
    [setValue],
  );

  return tuple(value, setValue, {value, onChange});
};

export default useControl;
