import React from 'react';
import {Trans} from 'react-i18next';

import {Course, Search} from './opencast';

import sitemap from './sitemap';

import createTable from './table';
import {usePagination} from './pagination';

export const Table = createTable<Course>([
  ['Title', course => <sitemap.course.Link id={course.id}>
    {course.title}
  </sitemap.course.Link>],
  ['Presenters', ({presenter}) => <>{presenter}</>],
  ['Location', ({location}) => <>{location}</>],
]);

const Courses: React.FC = () => {
  const pagination = usePagination();
  return <Search fetch={
    React.useCallback(opencast => opencast.series(pagination), [pagination])
  }>{
    ({courses, total}) => <>
      <h1><Trans count={total}>
        Courses ({{count: total}})
      </Trans></h1>
      <Table
        rows={courses}
        pagination={{total, ...pagination}}
      />
    </>
  }</Search>;
};

export default Courses;
