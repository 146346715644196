import React from 'react';
import {Trans} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {Location} from 'history';

import * as opencast from './opencast';
import {Table as RecordingsTable} from './recordings';
import {Table as CoursesTable} from './courses';

import sitemap from './sitemap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import styles from './search.module.css';
import {usePagination} from './pagination';

const getQuery = (location: Location) =>
  new URLSearchParams(location.search).get('query') || undefined;

export const SearchBar = withRouter(({history, location}) => {

  const [query, setQuery] = React.useState(getQuery(location) || '');

  const search = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    history.push(sitemap.search.routeTo({query}));
  };

  return <form onSubmit={search}>
    <input value={query} onChange={event => setQuery(event.target.value)} />
    <button className={styles.button}>
      <FontAwesomeIcon icon={faSearch} />
    </button>
  </form>
});

export const SearchResults: React.FC<{query?: string}> = ({query}) => {
  const episodePagination = usePagination();
  const seriesPagination = usePagination();
  return <opencast.Search fetch={
    React.useCallback(opencast => Promise.all([
      opencast.episodes({query, ...episodePagination}),
      opencast.series({query, ...seriesPagination}),
    ]), [query, episodePagination, seriesPagination])}>{([
      {recordings, total: totalRecordings},
      {courses, total: totalCourses},
    ]) => <>
      <h1><Trans values={{query}}>Search results for "{{query}}"</Trans></h1>
      <h2><Trans count={totalRecordings}>
        Recordings ({{count: totalRecordings}})
      </Trans></h2>
      <RecordingsTable
        rows={recordings}
        pagination={{total: totalRecordings, ...episodePagination}}
      />
      <h2><Trans count={totalCourses}>
        Courses ({{count: totalCourses}})
      </Trans></h2>
      <CoursesTable
        rows={courses}
        pagination={{total: totalCourses, ...seriesPagination}}
      />
    </>}
  </opencast.Search>;
};
