import React from 'react';
import {useTranslation} from 'react-i18next';
import {i18n} from 'i18next';

import styles from './LanguageSwitcher.module.css';

const LanguageRadioButton: React.FC<{
  language: 'de' | 'en',
  i18n: i18n
}> = ({language, i18n}) => {
  const id = `language-switcher-${language}`;
  return <div>
    <input
      type="radio"
      name="language"
      id={id}
      value={language}
      checked={i18n.languages.includes(language)}
      onChange={event => i18n.changeLanguage(event.target.value)}
    />
    <label htmlFor={id}>
      <span>{language.toUpperCase()}</span>
    </label>
  </div>;
};

const LanguageSwitcher: React.FC = () => {
  const {i18n} = useTranslation();
  return <div className={styles.languageSwitcher}>
    <LanguageRadioButton language="de" {...{i18n}} />
    <LanguageRadioButton language="en" {...{i18n}} />
  </div>;
};

export default LanguageSwitcher;
