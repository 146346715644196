import React from 'react';
import {Trans} from 'react-i18next';
import Carousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

import {Search, Recording, SortDirection} from './opencast';

import {Table as RecordingsTable} from './recordings';

import sitemap from './sitemap';

import styles from './Home.module.css';
import button from './button.module.css';
import {withRouter, RouteComponentProps} from 'react-router';

const Render = withRouter(
  ({recordings, history}: RouteComponentProps & {
    recordings: Recording[],
  }) =>
    <>
      <div className={styles.carousel}>
        <Carousel
          responsive={{0: {items: 1}}}
          buttonsDisabled={true}
          items={recordings.slice(0, 5).map(
            ({id, title, presenters, preview}) => <>
              <sitemap.recording.Link id={id}>
                <img className={styles.preview} alt="" src={preview} />
              </sitemap.recording.Link>
              <h1>
                <sitemap.recording.Link id={id}>
                  <span className={`${styles.ellipses} ${styles.title}`}>
                    {title}
                  </span>
                </sitemap.recording.Link>
                <span className={`${styles.ellipses} ${styles.presenters}`}>
                  {presenters.join(', ')}
                </span>
              </h1>
              <button
                className={`${styles.button} ${button.class}`}
                onClick={() => history.push(sitemap.recording.routeTo({id}))}
              ><Trans>Watch now</Trans></button>
            </>,
          )} />
      </div>
      <h1><Trans>New Recordings</Trans></h1>
      <RecordingsTable rows={recordings} />
    </>
);

const Home: React.FC = () => <Search fetch={
  React.useCallback(opencast => opencast.episodes({
    limit: 10,
    sort: {field: 'modified', direction: SortDirection.ASC},
  }), [])
}>{
  recordings => <Render {...recordings} />
}</Search>;

export default Home;
