import React from 'react';
import {Trans} from 'react-i18next';

import {formatDuration, Recording, Search} from './opencast';

import sitemap from './sitemap';

import createTable from './table';
import {usePagination} from './pagination';

import styles from './recordings.module.css';

export const columns: [string, React.FC<Recording>][] = [
  ['Title', ({id, title}) => <sitemap.recording.Link {...{id}}>
    {title}
  </sitemap.recording.Link>],
  ['Course', ({course}) => <>{course && <sitemap.course.Link id={course.id}>
    {course.title}
  </sitemap.course.Link>}</>],
  ['Date', ({date}) => <>{new Intl.DateTimeFormat('default', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).format(date)}</>],
  ['Duration', ({duration}) => <div className={styles.duration}>
    {formatDuration(duration)}
  </div>],
];

export const Table = createTable<Recording>(columns);

const Recordings: React.FC = () => {
  const pagination = usePagination();
  return <Search fetch={
    React.useCallback(opencast => opencast.episodes(pagination), [pagination])
  }>{
    ({recordings, total}) => <>
      <h1><Trans count={total}>
        Recordings ({{count: total}})
      </Trans></h1>
      <Table
        rows={recordings}
        pagination={{total, ...pagination}}
      />
    </>
  }</Search>;
};

export default Recordings;
