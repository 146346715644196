import React from 'react';
import {Trans} from 'react-i18next';

import {Pager, Pagination} from './pagination';

import styles from './table.module.css';

const createTable = <T extends {id: any}>(
  columns: [string, React.ComponentType<T>][],
): React.ComponentType<{
  rows: T[],
  pagination?: Pagination,
}> => ({rows, pagination}) => <>
  <table className={styles.table}>
    <thead>
      <tr>{columns.map(
        ([title]) => <th key={title}><Trans>{title}</Trans></th>
      )}</tr>
    </thead>
    <tbody>{
      rows.map(row => <tr key={row.id}>{columns.map(
        ([title, Render]) => <td key={title}><Render {...row} /></td>
      )}</tr>)
    }</tbody>
  </table>
  {pagination && <Pager {...(pagination as Pagination)} />}
</>;

export default createTable;
